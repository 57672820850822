const formatTime = date => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()

    return [year, month, day].map(formatNumber).join('/') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

//转年月日
function transformDay(time) {
    var today = new Date(time);
    var oYear = today.getFullYear();
    var oMoth = (today.getMonth() + 1).toString();
    if (oMoth.length <= 1) oMoth = '0' + oMoth;
    var oDay = today.getDate().toString();
    if (oDay.length <= 1) oDay = '0' + oDay;
    return oYear + '年' + oMoth + '月' + oDay + '日';
}

function transformDay2(time) {
    var today = new Date(time);
    var oYear = today.getFullYear();
    var oMoth = (today.getMonth() + 1).toString();
    if (oMoth.length <= 1) oMoth = '0' + oMoth;
    var oDay = today.getDate().toString();
    if (oDay.length <= 1) oDay = '0' + oDay;
    var h = today.getHours() < 10 ? '0' + today.getHours() + ':' : today.getHours() + ':';
    var m = today.getMinutes() < 10 ? '0' + today.getMinutes() + ':' : today.getMinutes() + ':';
    var s = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds();
    return oYear + '年' + oMoth + '月' + oDay + '日' + ' ' + h + m + s;
}

function transformDay3(time) {
    var today = new Date(time);
    var oYear = today.getFullYear();
    var oMoth = (today.getMonth() + 1).toString();
    if (oMoth.length <= 1) oMoth = '0' + oMoth;
    var oDay = today.getDate().toString();
    if (oDay.length <= 1) oDay = '0' + oDay;
    return oYear + '-' + oMoth + '-' + oDay;
}

//将时间戳转换成正常时间格式
function timestampToTime(timestamp, isdate) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '/';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
    var D = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate();
    if (isdate)
        return Y + M + D;
    D += ' ';
    var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
    var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
    var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
}

//num -1:昨天，1:明天, str是分割号
function getDay(num, str, time) {
    var today = time ? new Date(time) : new Date();
    var nowTime = today.getTime();
    var ms = 24 * 3600 * 1000 * num;
    today.setTime(parseInt(nowTime + ms));
    var oYear = today.getFullYear();
    var oMoth = (today.getMonth() + 1).toString();
    if (oMoth.length <= 1) oMoth = '0' + oMoth;
    var oDay = today.getDate().toString();
    if (oDay.length <= 1) oDay = '0' + oDay;
    return oYear + str + oMoth + str + oDay;
}

/**
 * 深层拷贝
 * @param {对象} initalObj
 */
const deepClone = function (initalObj) {
    var obj = {};
    obj = JSON.parse(JSON.stringify(initalObj));
    return obj;
}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : '0' + n
}

/**
 * 名称校验
 * @param {名称} value
 */
function isName(value) {
    if (!/^[\u4e00-\u9fa5]{2,6}$/.test(value)) {
        return false
    }
    else {
        return true
    }
}

/**
 * 公司名称校验
 * @param {名称} value
 */
function isCompanyName(value) {
    if (!/^[A-Za-z\u4E00-\u9FA5]{2,20}$/.test(value)) {
        return false
    }
    else {
        return true
    }
}

/**
 * 手机号码校验
 * @param {手机号码} value
 */
function isPhone(value) {
    if (!/^1(3|4|5|7|8)\d{9}$/.test(value)) {
        return false
    }
    else {
        return true
    }
}

/**
 * 车牌号码校验
 * @param {车牌号} str
 */
function isLicenseNo(vehicleNumber) {
    var xreg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}(([0-9]{5}[DF]$)|([DF][A-HJ-NP-Z0-9][0-9]{4}$))/;
    var creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳]{1}$/;
    if (vehicleNumber.length == 7) {
        return creg.test(vehicleNumber);
    }
    else if (vehicleNumber.length == 8) {
        return xreg.test(vehicleNumber);
    }
    else {
        return false;
    }
}

// 车辆识别代码校验
function isCarVin(value) {
    if (!/^[0-9A-Za-z]{1,17}$/.test(value)) {
        return false
    }
    else {
        return true
    }
}

// 发动机号校验
function isEngineNo(value) {
    return /^[0-9A-Za-z\-\u4e00-\u9fa5]{1,20}$/.test(value);
}

//邮箱校验
function isEmail(value) {
    return /^\w+((-\w+)|(\.\w+))*[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(value);
}

//营业执照校验
function isLicense(value) {
    if (!/(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/.test(value)) {
        return false
    }
    else {
        return true
    }
}

/**
 * 身份证校验粗略版
 * @param {身份证号码} value
 */
function isCard(value) {
    if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
        return false
    }
    else {
        return true
    }
}

function jsToFormData(name, data) { //对象转formdata格式
    let formData = '';
    for (var i in data) {
        var item = data[i];
        for (var d in item) {
            var key = d; // 属性名name 
            var value = item[key]; //属性值gao
            if (formData == '')
                formData += `${name}[${i}].${key}=${value}`
            else
                formData += `&${name}[${i}].${key}=${value}`
        }
    }
    return formData;
}

/**
 * 对象转表单格式
 * @param {参数} object1
 */
function objectToFormData(name, object) {
    if (typeof object != 'object' || object == null)
        return object;

    var obj = "";
    const keys = Object.keys(object);
    for (let index = 0; index < keys.length; index++) {
        var value = "";
        const item = object[keys[index]];
        if (typeof item != 'object')
            value = `${name}.${keys[index]}=${item}`;
        else if (item)
            value = objectToFormData(`${name}.${keys[index]}`, item);
        else
            value = `${name}.${keys[index]}=`;
        if (obj === "")
            obj = value;
        else
            obj += "&" + value;
    }
    return obj;
}

function toFormData(prefix, obj) {
    if (!isObject(obj)) return obj;

    if (prefix) prefix = `${prefix}.`;//前缀

    var result = new Array();
    var keys = Object.keys(obj);
    keys.forEach(key => {
        toFormDataItem(prefix, key, obj[key], result);
    });

    return result.join('&');
}

function toFormDataItem(prefix, key, val, result) {
    if (!isObject(val)) {
        if (val) val = val.toString().replace(/&/g, "＆");//替换特殊字符＆
        result.push(`${prefix}${key}=${val}`);
        return;
    }

    if (val instanceof Array) {
        for (var index = 0; index < val.length; index++) {
            var item = val[index];
            var keys = Object.keys(item);
            keys.forEach(_key => {
                toFormDataItem(prefix, `${key}[${index}].${_key}`, item[_key], result);
            });
        }
    }
    else if (isObject(val)) {
        var keys = Object.keys(val);
        keys.forEach(_key => {
            toFormDataItem(prefix, `${key}.${_key}`, val[_key], result);
        });
    }
}

//截取url的参数
function getQueryVariable(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return (false);
}


function getMchLogoUrl(mchId) {
    var logo = "";
    var mchHost = work_ctx.ossHost;
    if (mchHost.endsWith('/'))
        logo = mchHost + "image/mch_logo/" + mchId + ".png";
    else
        logo = mchHost + "/image/mch_logo/" + mchId + ".png";

    console.log('mch logo:', logo);
    return logo;
}

function getUriParameters(strParamName) {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    return params.get(strParamName);
}

Date.prototype.format = function (formatStr) {
    var str = formatStr;
    var Week = ['日', '一', '二', '三', '四', '五', '六'];
    str = str.replace(/yyyy|YYYY/, this.getFullYear());
    str = str.replace(/MM/, (this.getMonth() + 1) > 9 ? (this.getMonth() + 1).toString() : '0' + (this.getMonth() + 1));
    str = str.replace(/dd|DD/, this.getDate() > 9 ? this.getDate().toString() : '0' + this.getDate());
    str = str.replace(/hh|HH/, this.getHours() > 9 ? this.getHours().toString() : '0' + this.getHours());
    str = str.replace(/mm/, this.getMinutes() > 9 ? this.getMinutes().toString() : '0' + this.getMinutes());
    str = str.replace(/ss/, this.getSeconds() > 9 ? this.getSeconds().toString() : '0' + this.getSeconds());
    return str;
}
Date.prototype.format = function (formatStr) {
    let str = formatStr;
    let Week = ['日', '一', '二', '三', '四', '五', '六'];
    str = str.replace(/yyyy|YYYY/, this.getFullYear());
    str = str.replace(/MM/, (this.getMonth() + 1) > 9 ? (this.getMonth() + 1).toString() : '0' + (this.getMonth() + 1));
    str = str.replace(/dd|DD/, this.getDate() > 9 ? this.getDate().toString() : '0' + this.getDate());
    str = str.replace(/hh|HH/, this.getHours() > 9 ? this.getHours().toString() : '0' + this.getHours());
    str = str.replace(/mm/, this.getMinutes() > 9 ? this.getMinutes().toString() : '0' + this.getMinutes());
    str = str.replace(/ss/, this.getSeconds() > 9 ? this.getSeconds().toString() : '0' + this.getSeconds());
    str = str.replace(/dw/, '星期' + Week[this.getDay()]);
    str = str.replace(/zw/, '周' + Week[this.getDay()]);
    let ms = this.getMilliseconds();
    if (ms < 10) {
        ms = '00' + this.getMilliseconds();
    }
    else if (ms < 100) {
        ms = '0' + this.getMilliseconds();
    }
    else {
        ms = this.getMilliseconds();
    }
    str = str.replace(/fff/, ms);

    return str;
}
Date.prototype.addDays = function (days) {
    this.setDate(this.getDate() + days);
    return this;
};

Date.prototype.addMonths = function (months) {
    this.setMonth(this.getMonth() + months);
    return this;
};

Date.prototype.addYears = function (years) {
    this.setFullYear(this.getFullYear() + years);
    return this;
};

Date.prototype.addHours = function (hours) {
    this.setHours(this.getHours() + hours);
    return this;
};

Date.prototype.addMinutes = function (minutes) {
    this.setMinutes(this.getMinutes() + minutes);
    return this;
};

Date.prototype.addSeconds = function (seconds) {
    this.setSeconds(this.getSeconds() + seconds);
    return this;
};

Date.prototype.addMilliseconds = function (seconds) {
    this.setMilliseconds(this.getMilliseconds() + seconds);
    return this;
};


// 获取 seeeionStorage
function getSessionStorageItem(item) {
    return JSON.parse(sessionStorage.getItem(item))
}

// base64转化为图片
function base64ImgToFile(dataUrl, fileName = 'file') {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split('/')[1]
    const bStr = atob(arr[1]);
    let n = bStr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
    }
    return new File([u8arr], `${fileName}.${suffix}`, {type: mime});
}


export default {
    formatTime,
    transformDay,
    transformDay2,
    transformDay3,
    timestampToTime,
    getDay,
    deepClone,
    isName,
    isCompanyName,
    isPhone,
    isLicenseNo,
    isCarVin,
    isEngineNo,
    isEmail,
    isLicense,
    isCard,
    jsToFormData,
    objectToFormData,
    toFormData,
    getQueryVariable,
    getMchLogoUrl,
    getUriParameters,
    getSessionStorageItem,
    base64ImgToFile,

    /**
     * 转化月份为开始时间和结束时间
     * @param date
     * @returns {{startTime: Date, endTime: Date}}
     * @constructor
     */
    monthToStartEndTime(date) {
        let nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        let endTime = new Date(nextMonth - 1);
        return {
            startTime: date,
            endTime: endTime
        };
    },
    todayToStartEndTime() {
        const now = new Date();
        const startOfDay = new Date(now.setHours(0, 0, 0, 0));  // 今天的开始时间
        const endOfDay = new Date(now.setHours(23, 59, 59, 999));  // 今天的结束时间
        return {startTime: startOfDay, endTime: endOfDay};
    },
    yesterdayToStartEndTime() {
        const now = new Date();
        const yesterday = new Date(now.setDate(now.getDate() - 1));
        const startOfDay = new Date(yesterday.setHours(0, 0, 0, 0));  // 昨天的开始时间
        const endOfDay = new Date(yesterday.setHours(23, 59, 59, 999));  // 昨天的结束时间
        return {startTime: startOfDay, endTime: endOfDay};
    },
    last7DayToStartEndTime() {
        const now = new Date();
        const startOfPeriod = new Date(now.setDate(now.getDate() - 6));  // 7天前的日期
        const startOfPeriodAtStartOfDay = new Date(startOfPeriod.setHours(0, 0, 0, 0));  // 7天前的开始时间
        const endOfPeriodAtEndOfDay = new Date();  // 今天的结束时间
        return {startTime: startOfPeriodAtStartOfDay, endTime: endOfPeriodAtEndOfDay};
    },
    last30DayToStartEndTime() {
        const now = new Date();
        const startOfPeriod = new Date(now.setDate(now.getDate() - 30));  // 30天前的日期
        const startOfPeriodAtStartOfDay = new Date(startOfPeriod.setHours(0, 0, 0, 0));  // 30天前的开始时间
        const endOfPeriodAtEndOfDay = new Date();  // 今天的结束时间
        return {startTime: startOfPeriodAtStartOfDay, endTime: endOfPeriodAtEndOfDay};
    },
    thisMonthToStartEndTime() {
        const now = new Date();
        const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);  // 本月的第一天
        const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);  // 本月的最后一天
        return {startTime: startOfMonth, endTime: endOfMonth};
    },
    lastMonthToStartEndTime() {
        const now = new Date();
        const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);  // 上月的第一天
        const endOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);  // 上月的最后一天
        return {startTime: lastMonth, endTime: endOfLastMonth};
    }
}