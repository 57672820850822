import Vue from 'vue'
import App from './App.vue'
//可以动态修改浏览器标题的插件
//https://blog.csdn.net/wang13679201813/article/details/126639129
import VueWechatTitle from 'vue-wechat-title'

Vue.use(VueWechatTitle);

import echarts from '@/Echarts/echarts'    // 引入echarts
Vue.prototype.$echarts = echarts;

import router from './router'
import store from './store'
import ElementUI, {Loading} from 'element-ui'
import routerUtil from '@/utils/router_util.js'
import navigationUtil from '@/utils/navigation_util.js'
import utils from '@/utils/utils.js'

import 'element-ui/lib/theme-chalk/index.css';
// import '@styles/Site.scss'
import 'amfe-flexible';    // 用于自动设置 rem 基准值
// import 'amfe-flexible';    // 用于自动设置 rem 基准值
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';


import 'weui';
import weui from 'weui.js';

// 挂载到vue原型上，全局可以调用
import {http, api} from "@/api/http";
import {request} from "@/api/request";
import {constApi} from "@/api/consts.js";
import vueEventBus from "vue-geventbus";
import VueApexCharts from 'vue-apexcharts'

console.log('is there weui?', weui);
Vue.prototype.$weui = weui;
Vue.prototype.$http = http;
Vue.prototype.$api = api;
Vue.prototype.$request = request;
Vue.prototype.$navigation = navigationUtil;
Vue.prototype.$utils = utils;

Vue.prototype.$log = function (...messages) {
    console.group('[VueComponent: ' + this.$options.name + ']');
    console.log(...messages);
    console.groupEnd();
};
console.log('环境', process.env.NODE_ENV);
import Vconsole from 'vconsole';
if (process.env.NODE_ENV !== 'production' || utils.getQueryVariable('isConsole')) {       // 判断环境
    const vConsole = new Vconsole;
}


/**
 * 重量级Loading，整个页面会有遮罩
 * 基于element-ui的loading
 * @param text loading时的文字
 * @param fullscreen 是否满屏
 * @param body loading是否附加到body下
 * @param target 遮罩遮住的DOM
 * @param background 背景色，默认白色
 * @returns {ElLoadingComponent}
 */
Vue.prototype.$showLoading = function (text, fullscreen, body, target, background = 'rgp(0,0,0,0.5)') {
    return Loading.service({
        text: text,
        fullscreen: fullscreen,
        body: body,
        target: target,
        background: background
    });
};

// 轻量级的Loading，不会有遮罩
// 使用$$loading取代该用法
Vue.prototype.$layer = {
    loading() {
        return layer.load(2);
    },
    closeLoading(loadingInstance) {
        layer.close(loadingInstance);
    }
};

const $$loadingClass = {
    l: null,
    loading(){
        this.l = layer.load(2)
        return this;
    },
    close(){
        layer.close(this.l);
    }
}
// 轻量级的Loading，不会有遮罩
// 使用该对象取代Vue.prototype.$layer
Vue.prototype.$$loading = function() {
    let ins = Object.create($$loadingClass);
    ins.loading();
    return ins;
};

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(ElementUI);
Vue.use(VueLoaders);
Vue.use(constApi);
Vue.use(vueEventBus);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)
window.onerrot = (message, source, lineno, colno, error) => {
    console.error({
        message, source, lineno, colno, error
    })
};
checkIsAuthorized((res) =>
    init(() => {
        router.beforeEach((to, from, next) => {
            console.log('进入路由：', to);
            
            
            /* 路由发生变化修改页面title */
            var allow = Vue.prototype.$work_ctx.checkPermissions(`${to.meta.code}`);//检查权限
            //AccessDenied1、AccessDenied2，解决vue重复路由页面无法重新执行渲染界面
            if ((to.name != "AccessDenied1" && to.name != "AccessDenied2") && allow == false) {
                var timestamp = Math.round(new Date() / 1000);
                if (router.currentRoute.name == 'AccessDenied1')
                    next(`/${to.params.mchId}/AccessDenied2?timestamp=${timestamp}&code=${to.meta.code}&title=${to.meta.title}`);
                else
                    next(`/${to.params.mchId}/AccessDenied1?timestamp=${timestamp}&code=${to.meta.code}&title=${to.meta.title}`);
            }
            else
            {
                next();
            }
        });
        
        setRouters(res);
        
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
);

/**
 * 动态设置路由
 * @param {any} res
 */
function setRouters(res) {
    // console.log('菜单-->', res.data.rotues)
    var routers = routerUtil.convert(res.data.rotues);
    
    // router.addRoute({
    //     path: '/:mchId(\\d+)',
    //     name: 'NotFound',
    //     component: () => import('@/views/NotFound/Index.vue')
    // });
    
    for (var i = 0; i < routers.length; i++) {
        router.addRoute(routers[i]);
    }
    
    router.addRoute({
        path: '/:mchId(\\d+)/NotFound',
        name: 'NotFound',
        component: () => import('@/views/NotFound/Index.vue')
    });
    
    router.addRoute({
        path: '/:mchId(\\d+)/AccessDenied1',
        name: 'AccessDenied1',
        component: () => import('@/views/Common/AccessDenied.vue')
    });
    
    router.addRoute({
        path: '/:mchId(\\d+)/AccessDenied2',
        name: 'AccessDenied2',
        component: () => import('@/views/Common/AccessDenied2.vue')
    });
    
    router.addRoute({
        path: "/:mchId(\\d+)/*", // 此处需特别注意置于最底部，重定向
        redirect: "/:mchId(\\d+)/NotFound"
    });
    
}


/**
 * 进入APP之前检测是否已授权
 * window.location.href = https://localhost:44327/1/Home
 * window.location.origin = https://localhost:44327
 * window.location.pathname = /1/Home
 * @constructor
 */
function checkIsAuthorized(callback) {

    //* 判断重定向次数，防止陷入授权死循环 */
    var sessionKey = "checkIsAuthorized";
    var checkIsAuthorizedValue = sessionStorage.getItem(sessionKey);
    if (checkIsAuthorizedValue == undefined || checkIsAuthorizedValue == null) {
        checkIsAuthorizedValue = 0;
    }
    if (checkIsAuthorizedValue > 1) {
        sessionStorage.removeItem(sessionKey);
        alert("idsrv4授权繁忙，请稍后重试！");
        return;
    }


    var that = this;
    console.log('检查idsrv4授权信息...');
    let xhr = new XMLHttpRequest();
    var url = api.build("/Client/Check");
    xhr.open("GET", url);
    xhr.setRequestHeader("x-requested-with", "XMLHttpRequest");
    xhr.onload = () => {
        let res = JSON.parse(xhr.responseText);
        console.log('check result: ', res);
        if (res == null)
        {
            sessionStorage.removeItem(sessionKey);
            console.error('检查idsrv4授权信息错误', xhr);
            alert(xhr.responseText)
        }
        else if (res.code === 401)
        {
            sessionStorage.setItem(sessionKey, parseInt(checkIsAuthorizedValue) + 1);
            window.location = api.build('/Client/RedirectTo?redirectUrl=' + encodeURIComponent(window.location.href));
        }
        else if (res !== null && res.code === 200)
        {
            sessionStorage.removeItem(sessionKey);
            var data = res.data;
            data["checkPermissions"] = function (code) {
                var rCode = `v2_${code}`;
                
                var rotue = data.rotues.find(x => x.code == code);
                if (!rotue) return true;
                var permissionArray = Vue.prototype.$work_ctx.identity.permissions.split(',');
                if (rotue.allowAnonymous == false && permissionArray.indexOf(rCode) == -1)
                {
                    console.log('不包含权限！', permissionArray, code);
                    return false;
                }
                else
                {
                    return true;
                }
            };
            
            Vue.prototype.$work_ctx = data;
            navigationUtil.Router.set(res.data.rotues);
            
            callback(res);
        }
        else
        {
            sessionStorage.removeItem(sessionKey);
            console.error('检查idsrv4授权信息错误', xhr);
            alert(res.message)
        }
    };
    xhr.send();
}


function init(callback) {
    console.log('Vue 初始化...');
    
    if (!window.entryUrl)
    {
        // 记录进入app的url，后面微信sdk签名用
        //https://zhuanlan.zhihu.com/p/32227843
        window.entryUrl = location.href.split('#')[0];
        Vue.prototype.$getWxSignHref = function () {
            const userAgent = navigator.userAgent;
            if (/iPhone|iPad|iPod/i.test(userAgent) && !/wechatdevtools/i.test(userAgent))
                return encodeURIComponent(window.entryUrl.split('#')[0]);
            else
                return encodeURIComponent(window.location.href.split('#')[0]);
        }
    }

    callback();
}